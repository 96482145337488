import React, { Fragment, useState, useEffect, useReducer } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Card,
  Dropdown,
  Button,
  Alert,
  Modal,
  Spinner,
  Image,
  Badge,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import DataTable from "../../components/table";
import Export from "../../components/export";

import { postData } from "../../services/apiService";
import { resendVerificationEmail } from "../../services/accountService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";
import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing users.
 * @returns {React.Element} - Returns JSX for managing users.
 * @access Accessible by SuperAdmin.
 */
const ManageUsers = () => {
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token to extract user information
  const user = decodeToken(userSession);

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  // State and functions for controlling the Export Modal
  const [showExportModal, setShowExportModal] = useState(false);
  const closeExportModal = () => setShowExportModal(false);
  const openExportModal = () => setShowExportModal(true);

  // Columns configuration for the user table
  const columns = [
    {
      header: "Name",
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
    },
    {
      header: "Roles",
      accessorKey: "roles",
      cell: (cellProps) => {
        const { roles, validatorStatus } = cellProps.row.original;
        return (
          <Fragment>
            {roles.map((role, index) => (
              <Fragment key={role}>
                {role}
                {role === "Validator" && validatorStatus !== "Active" && (
                  <Badge
                    className="fw-semibold text-dark text-uppercase ms-1"
                    bg={validatorStatus === "Inactive" ? "warning" : "danger"}
                  >
                    {validatorStatus}
                  </Badge>
                )}
                {index !== roles.length - 1 && ", "}
              </Fragment>
            ))}
          </Fragment>
        );
      },
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Created On",
      accessorKey: "createdOn",
    },
    {
      header: "Last Activity",
      accessorKey: "lastActivity",
    },
    {
      header: "Actions",
      accessorKey: "id",
      cell: ({ getValue, row }) => (
        <Dropdown drop="down" className="more-menu">
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item to={`/user/edit/${getValue()}`} as={Link}>
              <i className="flaticon-edit fs-6 me-1"></i>Edit
            </Dropdown.Item>
            {getValue() !== user.nameid && (
              <Dropdown.Item onClick={() => showConfirmation(getValue())}>
                <i className="flaticon-delete fs-6 me-1 align-text-bottom"></i>
                Delete
              </Dropdown.Item>
            )}
            {!row.original.isEmailConfirmed && (
              <Dropdown.Item
                onClick={() => resendEmailConfirmation(row.original.email)}
              >
                <i className="flaticon-mail fs-6 me-1"></i> Resend Email
                Confirmation
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  /**
   * Function to handle deletion of a user by updating their status to "Deleted".
   * @param {number} id - The ID of the user to be deleted.
   */
  const handleDeleteUser = async (id) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/user/update-status/${id}/Deleted`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to resend email confirmation to a given email address
   * @param {string} email - The email address to which the confirmation email will be sent
   */
  const resendEmailConfirmation = async (email) => {
    setIsPageLoading(true);
    try {
      const response = await resendVerificationEmail(
        `/api/account/resend-verification-email/?email=${email}`
      );
      showNotification("success", response, 5000);
      setIsPageLoading(false);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a confirmation dialog for deleting a user.
   * @param {number} id - The ID of the user to be deleted.
   */
  const showConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                Do you really want to delete this user? <br />
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await handleDeleteUser(id);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Delete
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setUsers([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to handle dropdown change event for role selection.
   * @param {object} event - The dropdown change event object.
   */
  const onRoleChange = (event) => {
    handlePageChange(1, page.PageSize);
    setRole(event.target.value ?? "");
  };

  useEffect(() => {
    /**
     * Fetches the users from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/user/getByPage?searchTerm=${searchTerm}&role=${role}`,
          page,
          userSession
        );
        setUsers(response.users);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setUsers([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, page, userSession, role]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Manage Users</h6>
          <div>
            <Button variant="primary" onClick={openExportModal}>
              <i className="flaticon-downloads me-1"></i>Export
            </Button>
            <Link
              to="/user/create"
              className="ms-3 btn btn-secondary"
              title="Create User"
            >
              + Add Staff
            </Link>
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row className="align-items-center mx-0">
              <Col>
                <span className="fs-small text-dark-emphasis">
                  You can view the users by utilizing the role filter located on
                  the right.
                </span>
              </Col>
              <Col xs={6} sm={5} md={4} lg={4} xl={3} xxl={2} className="ps-0">
                <InputGroup>
                  <InputGroup.Text>
                    <i className="flaticon-invoice fs-6 text-secondary"></i>
                  </InputGroup.Text>
                  <Form.Select size="sm" onChange={onRoleChange}>
                    <option value="">Select Role</option>
                    <option value="SuperAdmin">Super Admin</option>
                    <option value="Staff">Staff</option>
                    <option value="Validator">Validator</option>
                    <option value="Donor">Donor</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col xs={12}>
                <hr className="my-4" />
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={users}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Export
        featureName="User"
        searchParams={JSON.stringify({ role: role })}
        showExportModal={showExportModal}
        closeExportModal={closeExportModal}
        showNotification={showNotification}
      />
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageUsers;
