import React, {
  Fragment,
  useState,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Button,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Badge,
  Form,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import DataTable from "../../components/table";
import Export from "../../components/export";
import { transformProfilePicture } from "../../components/transformFileURL";

import { getData, postData } from "../../services/apiService";
import { useAuthContext } from "../../context/authProvider";
import { decodeToken } from "../../utils/session";
import { notify, initialState } from "../../store/notification";
import {
  Host,
  NeedProfileURL,
  OrgProfileURL,
  ValidatorProfileURL,
} from "../../constants";

import confirmation from "../../resources/images/confirmation.png";

/**
 * Component for managing needs.
 * @returns {React.Element} - Returns JSX for managing needs.
 * @access Accessible by SuperAdmin, Staff, and Validator.
 */
const ManageNeeds = () => {
  const navigate = useNavigate();
  // Notification state and dispatch hook
  const [notification, dispatch] = useReducer(notify, initialState);
  // Destructure the 'userSession' object from the 'useAuthContext()' hook
  const { userSession } = useAuthContext();
  // Decode the user session token to extract user information
  const user = decodeToken(userSession);

  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [page, setPage] = useState({ PageNumber: 1, PageSize: 25 });
  const [needs, setNeeds] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [needStatus, setNeedStatus] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const profileUrlRef = useRef(null);

  // State and functions for controlling the Export Modal
  const [showExportModal, setShowExportModal] = useState(false);
  const closeExportModal = () => setShowExportModal(false);
  const openExportModal = () => setShowExportModal(true);

  // Columns configuration for the user table
  const columns = [
    {
      header: "Recipient",
      cell: (cellProps) => {
        return (
          <Fragment>
            <Link
              className="d-flex align-items-center text-decoration-none"
              to={`/needs/edit/${cellProps.row.original.needId}`}
            >
              <Image
                src={cellProps.row.original.profilePicture}
                alt="Benevolent"
                roundedCircle
                thumbnail
                fluid
                className="avatar"
              />
              <div className="ms-3 text-dark">
                {cellProps.row.original.firstName}{" "}
                {cellProps.row.original.lastName}
                <p className="mb-0 text-dark-emphasis">
                  <small className="fw-semibold">Alias: </small>
                  <small>{cellProps.row.original.displayName}</small>
                </p>
              </div>
            </Link>
          </Fragment>
        );
      },
    },
    {
      header: "Need",
      cell: (cellProps) => {
        return (
          <Fragment>
            <h6 className="text-dark">
              {cellProps.row.original.title
                ? cellProps.row.original.title
                : "[Untitled]"}
            </h6>
            <div className="fw-light fs-small">
              <span>Need Id: {cellProps.row.original.needId}</span>
              <span className="d-block">
                By{" "}
                <Link
                  to={`${ValidatorProfileURL}${cellProps.row.original.validatorReferenceId}`}
                  className="text-decoration-none fw-semibold"
                >
                  {cellProps.row.original.validatorName}
                </Link>{" "}
                at{" "}
                <Link
                  to={`${OrgProfileURL}${cellProps.row.original.organizationReferenceId}`}
                  className="text-decoration-none fw-semibold"
                >
                  {cellProps.row.original.organizationName}
                </Link>
              </span>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Days Left",
      accessorKey: "daysLeft",
    },
    {
      header: "Progress",
      cell: (cellProps) => {
        return (
          <Fragment>
            <div className="fw-light fs-small">
              <span className="fw-semibold">
                ${cellProps.row.original.raised}
              </span>
              <span className="fw-light px-1">of</span>
              <span className="fw-semibold">
                ${cellProps.row.original.amountNeeded}
              </span>
            </div>
          </Fragment>
        );
      },
    },
    {
      header: "Status",
      cell: (cellProps) => {
        let background;
        switch (cellProps.row.original.needStatus) {
          case "Draft":
            background = "light";
            break;
          case "Awaiting Approval":
            background = "warning";
            break;
          case "Published":
            background = "info";
            break;
          case "Funded":
            background = "success";
            break;
          case "Expired":
          case "Closed":
            background = "danger";
            break;
          default:
            background = "dark";
            break;
        }
        return (
          <Badge className="fw-semibold text-dark" bg={background}>
            {cellProps.row.original.needStatus.toUpperCase()}
          </Badge>
        );
      },
    },
    {
      header: "Last Activity",
      accessorKey: "submittedOn",
    },
    {
      header: "Actions",
      accessorKey: "needId",
      cell: (cellProps) => toggleActions(cellProps.row.original),
    },
  ];

  /**
   * Generates a dropdown menu containing actions for a specific need based on the user's role and the status of the need.
   * @param {object} row - The data object representing the need.
   * @returns {React.Element} - Returns JSX for dropdown menu component.
   */
  const toggleActions = (row) => {
    return (
      <Dropdown drop="down" className="more-menu">
        {((user.roles.includes("Validator") &&
          row.needStatus !== "Awaiting Approval") ||
          user.roles.includes("SuperAdmin") ||
          user.roles.includes("Staff")) && (
          <Dropdown.Toggle variant="light" size="sm">
            <i className="flaticon-more"></i>
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu>
          {(row.needStatus === "Published" || row.needStatus === "Funded") && (
            <Fragment>
              <Dropdown.Item
                to={`${NeedProfileURL}${row.referenceId}/${row.needId}`}
                as={Link}
              >
                <i className="flaticon-user-info fs-5 me-2"></i>View Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigateToUpdates(row.needId, row.displayName)}
              >
                <i className="flaticon-settings fs-5 me-2"></i>Updates
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  toggleShareModal(
                    row.referenceId,
                    row.needId,
                    row.title,
                    row.summary
                  )
                }
              >
                <i className="flaticon-share fs-5 me-2"></i>Share
              </Dropdown.Item>
            </Fragment>
          )}
          {row.needStatus === "Funded" && (
            <Dropdown.Item
              onClick={() =>
                navigateToReceipts(row.needId, row.title, row.displayName)
              }
            >
              <i className="flaticon-invoice fs-5 me-2"></i>Receipt
            </Dropdown.Item>
          )}
          {row.needStatus === "Funded" && (
            <Dropdown.Item onClick={() => notifyFundingDetails(row.needId)}>
              <i className="flaticon-notification fs-5 me-2"></i>Notify Funding
              Details
            </Dropdown.Item>
          )}
          {row.needStatus === "Expired" && (
            <Dropdown.Item
              onClick={() => showConfirmation(row.needId, "Funded")}
            >
              <i className="flaticon-dollar-circle fs-5 me-2"></i>Mark as Funded
            </Dropdown.Item>
          )}
          {((user.roles.includes("Validator") && row.needStatus === "Draft") ||
            user.roles.includes("SuperAdmin") ||
            user.roles.includes("Staff")) && (
            <Fragment>
              <Dropdown.Item to={`/needs/edit/${row.needId}`} as={Link}>
                <i className="flaticon-edit fs-5 me-2"></i>Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => showConfirmation(row.needId, "Deleted")}
              >
                <i className="flaticon-delete fs-6 me-2 align-text-bottom"></i>
                Delete
              </Dropdown.Item>
            </Fragment>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  /**
   * Updates the status of a need.
   * @param {string} id - The ID of the need.
   * @param {string} status - The new status of the need.
   */
  const updateStatus = async (id, status) => {
    setIsPageLoading(true);
    try {
      const response = await postData(
        `/api/need/update-status/`,
        {
          id: id,
          status: status,
          isFeatured: null,
        },
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
      setPage((prevPage) => ({
        ...prevPage,
        pageNumber: prevPage.pageNumber,
      }));
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Navigates to the updates page for a specific need.
   * @param {string} needId - The ID of the need.
   * @param {string} recipient - The recipient's display name of the need.
   */
  const navigateToUpdates = (needId, recipient) => {
    navigate(`/needs/updates/${needId}`, {
      replace: true,
      state: { recipient: recipient },
    });
  };

  /**
   * Navigates to the receipts page for a specific need.
   * @param {string} needId - The ID of the need.
   * @param {string} title - The title of the need.
   * @param {string} recipient - The recipient's display name of the need.
   */
  const navigateToReceipts = (needId, title, recipient) => {
    navigate(`/needs/receipts/${needId}`, {
      replace: true,
      state: { title: title, recipient: recipient },
    });
  };

  /**
   * Function to notify the account team about the funding details of a specific need.
   * @param {number} needId - The ID of the need to notify the account team about.
   */
  const notifyFundingDetails = async (needId) => {
    setIsPageLoading(true);
    try {
      const response = await getData(
        `/api/need/notifyFundingDetails/${needId}`,
        null,
        userSession
      );
      setIsPageLoading(false);
      showNotification("success", response, 5000);
    } catch (error) {
      showNotification("danger", error, 5000);
      setIsPageLoading(false);
    }
  };

  /**
   * Function to display a for deleting or designating a need as funded.
   * @param {string} id - The ID of the need.
   * @param {string} status - The status of the need (e.g., "Deleted" or "Funded").
   */
  const showConfirmation = (id, status) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3">
            <Card.Body className="p-4 text-center">
              <Image src={confirmation} alt="Delete Confirmation" fluid />
              <div className="mt-3 fw-light">
                {status === "Deleted" && (
                  <Fragment>
                    Do you really want to delete this need? <br />
                  </Fragment>
                )}
                {status === "Funded" && (
                  <Fragment>
                    Do you really want to designate this need as funded? <br />
                  </Fragment>
                )}
                This process can't be undone.
              </div>
            </Card.Body>
            <Card.Footer className="bg-transparent py-4 text-center">
              <Button
                title="Cancel"
                variant="link"
                className="link-danger fw-semibold"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="ms-4 btn-md"
                onClick={async () => {
                  try {
                    await updateStatus(id, status);
                  } catch (error) {
                    // showNotification("danger", error, 5000);
                  }
                  onClose();
                }}
              >
                Yes, Continue
              </Button>
            </Card.Footer>
          </Card>
        );
      },
    });
  };

  /**
   * Function to Displays a modal for sharing a need via various social media platforms or email, and allows copying the need URL.
   * @param {string} referenceId - The ID of the need to share.
   * @param {string} title - The title of the need.
   * @param {string} summary - A summary of the need.
   */
  const toggleShareModal = (referenceId, needId, title, summary) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card className="border-0 shadow rounded-3 modal-share">
            <Card.Body className="p-4">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="fw-semibold mb-0">Share Need</h5>
                <Button
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => onClose()}
                ></Button>
              </div>
              <hr />
              <p className="text-light-emphasis">Share this link via</p>
              <div className="my-4">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?&u=${encodeURI(
                    `${Host}/profile/need/${referenceId}/${needId}`
                  )} `}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon facebook"
                >
                  <i className="flaticon-facebook-outline fs-3"></i>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURI(
                    `${Host}/profile/need/${referenceId}/${needId}`
                  )} `}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon twitter"
                >
                  <i className="flaticon-twitter fs-3"></i>
                </a>
                <a
                  href={`mailto:?subject=${title}&body=${summary}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon email"
                >
                  <i className="flaticon-envelope fs-3"></i>
                </a>
              </div>
              <p className="text-light-emphasis mb-4">Or copy link</p>
              <InputGroup>
                <InputGroup.Text>
                  <i className="flaticon-link fs-3"></i>
                </InputGroup.Text>
                <FloatingLabel
                  label="Profile URL"
                  controlId="txtProfileURL"
                  htmlFor="txtProfileURL"
                >
                  <Form.Control
                    type="text"
                    ref={profileUrlRef}
                    value={`${Host}/profile/need/${referenceId}`}
                    readOnly
                  />
                </FloatingLabel>
                <InputGroup.Text className="p-0">
                  <Button
                    variant="secondary"
                    className="btn-md rounded-1 btn-copy"
                    onClick={copyToClipboard}
                  >
                    Copy
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Card.Body>
          </Card>
        );
      },
    });
  };

  /**
   * Function to copy the content of a specified element to the clipboard.
   */
  const copyToClipboard = () => {
    // Create a range and select the content of the specified element
    const range = document.createRange();
    range.selectNode(profileUrlRef.current);
    // Clear any existing selections and add the new range to the selection
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    // Remove the range from the selection
    window.getSelection().removeAllRanges();
  };

  /**
   * Function to handle page change event.
   * @param {number} pageNumber - The new page number.
   * @param {number} pageSize - The new page size.
   */
  const handlePageChange = (pageNumber, pageSize) => {
    setNeeds([]);
    setPage({
      PageNumber: pageNumber,
      PageSize: pageSize,
    });
  };

  /**
   * Function to handle search action.
   * @param {string} value - The search term entered by the user.
   */
  const handleSearch = (value) => {
    handlePageChange(1, page.PageSize);
    setSearchTerm(value);
  };

  /**
   * Function to handle dropdown change event for need status selection.
   * @param {object} event - The dropdown change event object.
   */
  const onStatusChange = (event) => {
    handlePageChange(1, page.PageSize);
    setNeedStatus(event.target.value ?? "");
  };

  /**
   * Convert a local date to UTC.
   * @param {string} date - The local date to be converted.
   * @returns {Date} The equivalent UTC date.
   */
  const convertLocalToUTCDate = (date) => {
    if (!date) return date;

    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  };

  /**
   * Constructs the search query object.
   * @returns {string} The JSON stringified search query object.
   */
  const constructSearchQuery = () => {
    const query = {};
    if (showExportModal) {
      if (fromDate) query.fromDate = convertLocalToUTCDate(fromDate);
      if (toDate) query.toDate = convertLocalToUTCDate(toDate);
    }
    return JSON.stringify(query);
  };

  /**
   * Function to validate the 'toDate' field.
   * @param {Date} date - The value of 'toDate' to be validated.
   */
  const validateToDate = (date) => {
    if (!date) {
      setToDate(date);
      return;
    }
    if (fromDate <= date) {
      handlePageChange(1, page.PageSize);
      setToDate(date);
    }
  };

  useEffect(() => {
    /**
     * Fetches the needs from the backend API
     * Executes when there is a change in the values of the dependencies in the useEffect hook.
     */
    const getAll = async () => {
      setIsLoading(true);
      try {
        const response = await postData(
          `/api/need/getByPage`,
          {
            page,
            searchTerm,
            status: needStatus,
            fromDate: fromDate ? convertLocalToUTCDate(fromDate) : fromDate,
            toDate: toDate ? convertLocalToUTCDate(toDate) : toDate,
          },
          userSession
        );
        const updatedNeeds = transformProfilePicture(response.needs);
        setNeeds(updatedNeeds);
        if (response.count != null) setTotalRecords(response.count);
        setIsLoading(false);
      } catch (error) {
        showNotification("danger", error, 5000);
        setNeeds([]);
        setIsLoading(false);
      }
    };

    getAll();

    // Return a cleanup function
    return () => {};
  }, [searchTerm, needStatus, page, userSession, fromDate, toDate]);

  /**
   * Function to dispatch an action to show a notification.
   * @param {string} variant - The variant of the notification (e.g., 'success', 'error', 'info').
   * @param {string} message - The message content of the notification.
   * @param {number} timeout - The duration in milliseconds before the notification auto-dismisses (optional).
   */
  const showNotification = (variant, message, timeout) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: {
        variant: variant,
        message: message,
        timeout: timeout,
      },
      dispatch: dispatch,
    });
  };

  return (
    <main>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="module-title fw-semibold mb-0">Manage Needs</h6>
          <div>
            {user.roles.includes("Validator") && (
              <Fragment>
                <Link
                  to="/validator/recommend-validator"
                  className="ms-3 btn btn-primary"
                  title="Post Need"
                >
                  Recommend Your Colleagues
                </Link>
                <Link
                  to="/needs/create"
                  className="ms-3 btn btn-secondary"
                  title="Post Need"
                >
                  + Post Need
                </Link>
              </Fragment>
            )}
            {(user.roles.includes("SuperAdmin") ||
              user.roles.includes("Staff")) && (
              <Button
                variant="primary"
                className="ms-3"
                onClick={openExportModal}
              >
                <i className="flaticon-downloads me-1"></i>Export
              </Button>
            )}
          </div>
        </div>
        <Card className="border-0 rounded-3">
          <Card.Body className="p-4">
            <Row className="align-items-center justify-content-center">
              <Col xs={12}>
                <p className="text-dark-emphasis text-center">
                  You can view the needs by utilizing the below filters
                </p>
              </Col>
              <Col
                xs={12}
                sm={3}
                md={"auto"}
                className="text-center text-sm-end text-md-start my-1"
              >
                <span className="form-label">Select Date Range: </span>
              </Col>
              <Col xs={5} sm={"auto"} className="my-1">
                <InputGroup className="date-range-picker">
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <i className="flaticon-calendar"></i>
                  </InputGroup.Text>
                  {/* Date picker component */}
                  <DatePicker
                    selected={fromDate}
                    onChange={(date) => {
                      handlePageChange(1, page.PageSize);
                      setFromDate(date);
                    }}
                    selectsStart
                    startDate={fromDate}
                    endDate={toDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="From Date"
                    isClearable
                    className="form-control border-start-0 rounded-start-0"
                  />
                </InputGroup>
              </Col>
              <Col xs={5} sm={"auto"} className="my-1">
                <InputGroup className="date-range-picker">
                  <InputGroup.Text className="bg-transparent border-end-0">
                    <i className="flaticon-calendar"></i>
                  </InputGroup.Text>
                  {/* Date picker component */}
                  <DatePicker
                    selected={toDate}
                    onChange={(date) => {
                      validateToDate(date);
                    }}
                    selectsEnd
                    startDate={fromDate}
                    endDate={toDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="To Date"
                    isClearable
                    className="form-control border-start-0 rounded-start-0"
                  />
                </InputGroup>
              </Col>
              <Col
                xs={"auto"}
                sm={4}
                md={4}
                lg={4}
                xl={3}
                xxl={2}
                className="my-1"
              >
                <InputGroup>
                  <InputGroup.Text>
                    <i className="flaticon-invoice fs-6 text-secondary"></i>
                  </InputGroup.Text>
                  <Form.Select
                    size="sm"
                    onChange={onStatusChange}
                    className="fs-small"
                  >
                    <option value="">Select Status</option>
                    <option value="Awaiting Approval">Awaiting Approval</option>
                    <option value="Published">Published</option>
                    <option value="Expired">Expired</option>
                    <option value="Closed">Closed</option>
                    <option value="Funded">Funded</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col xs={12}>
                <hr className="my-4" />
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={needs}
              totalRecords={totalRecords}
              page={page}
              onPageChange={handlePageChange}
              searchRecords={handleSearch}
              loadingState={isLoading}
            />
          </Card.Body>
        </Card>
      </Container>
      <div className={`notification ${notification.variant && "show"}`}>
        {notification.variant && (
          <Alert
            variant={notification.variant}
            onClose={() => dispatch({ type: "CLEAR_NOTIFICATION" })}
            dismissible
          >
            {notification.message}
          </Alert>
        )}
      </div>
      <Export
        featureName="Need"
        searchParams={constructSearchQuery()}
        showExportModal={showExportModal}
        closeExportModal={closeExportModal}
        showNotification={showNotification}
      />
      <Modal
        show={isPageLoading}
        aria-labelledby="Loading"
        className="modal-loading"
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ManageNeeds;
